Mrack {
  border-radius: 100vh;
  background: #d7dfe5;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #0054A6;
  border-radius: 100vh;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c0a0b9;
}

.from-accent {
  --tw-gradient-from: hsla(201, 96%, 32%);
  --tw-gradient-to: hsl(201 96% 32% / 0.3);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.submenu {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
}
